<template>
  <div class="alpha">

    <div class="section-1">
      <p class="header">FINRA BrokerCheck reports for Digital Sync Trading and its investment
        professionals are available at www.finra.org/brokercheck</p>
      <div class="section-1-inner">
        <p>1.   Restrictions apply. For additional information on interest rates, click here. Credit interest rate as
          of 06/02/23.</p>
        <p>2.   Lowest Cost Broker according to StockBrokers.com Online Broker Survey 2023: Read the full article Online
          Broker Reviews, May 18, 2023. "Because Digital Sync Trading' core clientele are professional traders and
          institutional investors (e.g., hedge funds), it is crucial to provide the lowest commissions schedule
          available. In our rigorous assessment, there is no question Digital Sync Trading delivers."</p>
        <p>3.    Restrictions apply. Annual Percentage Rate (APR) on USD margin loan balances for AML Pro as of
          06/02/23. Digital Sync Trading calculates the interest charged on margin loans using the applicable rates for
          each interest rate tier listed on its website. For additional information on margin loan rates, click here</p>
        <p>4.   ITA Lite provides commission-free trades in US exchange-listed stocks and ETFs. For complete information,
          see. </p>
        <p>5.   Lower investment costs will increase your overall return on investment, but lower costs do not guarantee
          that your investment will be profitable.</p>
        <p>6.   For complete information, see www.incometradeassets.com.</p>
        <p>7.   Available currencies vary by Digital Sync Trading affiliate.</p>
        <p>8.   Digital Sync Trading Group and its affiliates. For additional information view our Investors Relations -
          Earnings Release section by clicking here.</p>
        <p>9.   Digital Sync Trading rating by Standard & Pool's. View report</p>
      </div>

      </div>

  </div>
</template>

<script>

export default {
  name: 'footerText',
}
</script>

<style scoped>
.section-1{
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3%;
  margin-bottom: 2%;
  color: #071333;
}
.header{
  margin-bottom: 1%;
  color: #071333;
}
.section-1-inner p{
  padding-bottom: 10px;
  font-size: 15px;
}

@media (max-width: 700px) {
  .section-1{
    width: 92%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media (max-width: 500px) {

}
</style>